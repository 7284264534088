import { documentConstants } from "./action-types";
import { documentDataApi } from "../utils/api";
import { v4 as uuidv4 } from "uuid";
import { message } from "antd";
import dotEnv from "dotenv";
import { PropertyHandlers } from "../../PropertyComp/state/actions";
import { appStatusChange } from "../../App/state/actions";
import { accountHandle } from "../../Account/state/actions";
dotEnv.config();

const apiURL = process.env.REACT_APP_ADMIN_BASE_URL;

/**
 * All Actions for contract Page
 */

/**
 * To update Navbar title action
 * @param {string}
 */

const getDocumentDataError = (error) => {
  return {
    type: documentConstants.DOCUMENT_CATEGORY_FAILURE,
    getCategoryError: error,
    getCategoryLoading: false,
  };
};

const getDocumentDataSuccess = (response) => {
  return {
    type: documentConstants.DOCUMENT_CATEGORY_SUCCESS,
    getCategoryData: response.data.info,
    getCategoryLoading: false,
  };
};

const getDocumentData = () => {
  return (dispatch, getState) => {
    documentDataApi
      .documentCategoryRequest()
      .then((response) => {
        dispatch(getDocumentDataSuccess(response));
      })
      .catch((error) => {
        dispatch(getDocumentDataError(error));
      });
  };
};
// contract offer check

const postContractOfferCheckError = (error) => {
  return {
    type: documentConstants.CONTRACT_OFFER_CHECK_FAILURE,
    getContractOfferError: error,
    getContractOfferLoading: false,
  };
};

const resetOfferData = () => {
  return {
    type: documentConstants.RESET_OFFER_CHECK_DATA,
    getOfferData: [],
  };
};

const postContractOfferCheckSuccess = (response) => {
  return {
    type: documentConstants.CONTRACT_OFFER_CHECK_SUCCESS,
    getOfferData: response.data.info,
    getContractOfferLoading: false,
  };
};

const getContractOffer = (data) => {
  return (dispatch, getState) => {
    documentDataApi
      .contractOfferCheck(data)
      .then((response) => {
        dispatch(postContractOfferCheckSuccess(response));
      })
      .catch((error) => {
        dispatch(postContractOfferCheckError(error));
      });
  };
};

const urlImportSuccess = (response) => {
  return {
    type: documentConstants.URL_IMPORT_SUCCESS,
    payload: response?.data?.info?.transactionData || {},
  };
};

const pdfImportLoading = (response) => {
    return {
      type: documentConstants.PDF_IMPORT_LOADING,
      pdfImportLoading:true,
    };
  };

const urlImportError = (error) => {
  return {
    type: documentConstants.URL_IMPORT_ERROR,
    payload: error,
    urlImportErrorAccured: true,
  };
};

const pdfError = (value) => {
  return {
    type: documentConstants.PDF_IMPORT_ERROR,
    pdfImportError: value,
  };
};

const urlImportErrorClear = () => {
  return {
    type: documentConstants.URL_IMPORT_ERROR_CLEAR,
  };
};

const clearUrlData = () => {
  return {
    type: documentConstants.URL_IMPORT_CLEAR_SUCCESS,
    payload: {},
  };
};

const urlImportSuccessImport = (response, clonefromPdf) => {
  if (clonefromPdf) {
    message.success(response?.data?.info?.message || "Offer imported successfully for the selected property!");
    // setTimeout(() => {
    //   window.location.href = window.location.origin + window.location.pathname;
    // }, 2000);
  } else {
    let uniqId = uuidv4();
    window.name = uniqId;
    let redirectURL = `${apiURL}/contractDocument/${response?.data?.info?.createUrlDataResult?._id}`;
    window.location.assign(encodeURI(redirectURL));
  }
};

const urlImport = (data, redirect,buildData) => {
  return (dispatch, getState) => {
    documentDataApi
      .urlImport(data)
      .then((response) => {
        if (redirect) {
          urlImportSuccessImport(response, data.clonefromPdf);
          setTimeout(() => {
            dispatch(PropertyHandlers.offerTableUrlShorthandApiCalling(buildData,window.location.pathname))
          }, 2000);
        } else {
          dispatch(urlImportSuccess(response));
        }
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "We encountered an issue while doing urlImport. Please try again later!");
        if(data.clonefromPdf){
          if(error?.response?.data?.responseType === "OFFER_EXISTS"){
            dispatch(urlImportError(error));
            return
          }
          dispatch(pdfError(true));
          return;
        }
        dispatch(urlImportError(error));
      });
  };
};

const createRoleRequestRealtorSuccess = (response) => {
  return {
    type: documentConstants.REALTOR_CREATE_REQUEST_SUCCESS,
    payload: response.data.info,
  };
};

const createRoleRequestRealtorError = (error) => {
  return {
    type: documentConstants.REALTOR_CREATE_REQUEST_ERROR,
    payload: error,
  };
};

const createRoleRequestBuyerSuccess = (response) => {
  return {
    type: documentConstants.BUYER_CREATE_REQUEST_SUCCESS,
    payload: response.data.info,
  };
};

const createRoleRequestBuyerError = (error) => {
  return {
    type: documentConstants.BUYER_CREATE_REQUEST_ERROR,
    payload: error,
  };
};

const createRoleRequest = (data) => {
  return (dispatch, getState) => {
    documentDataApi
      .createRoleRequestApi(data)
      .then((response) => {
        if (data.roleType === "REALTOR") {
          dispatch(createRoleRequestRealtorSuccess(response));
        }
        if (data.roleType === "CUSTOMER") {
          dispatch(createRoleRequestBuyerSuccess(response));
        }
      })
      .catch((error) => {
        if (data.roleType === "REALTOR") {
          dispatch(createRoleRequestRealtorError(error));
        }
        if (data.roleType === "CUSTOMER") {
          dispatch(createRoleRequestBuyerError(error));
        }
      });
  };
};

// Build Document Offer Screen url shorthand

const buildContractOfferCheckError = (error) => {
  return {
    type: documentConstants.BUILD_OFFER_SHORTHAND_URL_FAILURE,
    buildContractError: error,
    buildContractLoading: false,
  };
};

// const buildContractOfferCheckSuccess = (response) => {
//   // return {
//   //   type: documentConstants.BUILD_OFFER_SHORTHAND_URL_SUCCESS,
//   //   buildContractData: response.data.info,
//   //   buildContractLoading: false,
//   // };
// };

const buildContractOffer = (data, currentPath) => {
  return (dispatch, getState) => {
    documentDataApi
      .buildDocumentUrlShortening(data)
      .then((response) => {
        if (response?.data?.info?._id) {
          const tabId = uuidv4();
          document.cookie = `${tabId}=${currentPath}; domain=.resure.realestate; path=/;`;
          window.location.href = `${apiURL}/contractDocument/${response?.data?.info?._id}?tabId=${tabId}`;
        }
      })
      .catch((error) => {
        dispatch(buildContractOfferCheckError(error));
      });
  };
};

const draftDocumentURl = (data, currentPath) => {
  return (dispatch, getState) => {
    documentDataApi
      .buildDocumentUrlShortening(data)
      .then((response) => {
        const tabId = uuidv4();
        document.cookie = `${tabId}=${currentPath}; domain=.resure.realestate; path=/;`;
        window.location.href = `${apiURL}/contractDocument/${response.data.info._id}?tabId=${tabId}`;
        // dispatch(buildContractOfferCheckSuccess(response));
      })
      .catch((error) => {
        // dispatch(buildContractOfferCheckError(error));
      });
  };
};

// prview Contract Offer

const prviewContractOfferFailure = (error) => {
  return {
    type: documentConstants.PREIVIEW_BUILD_CONTRACT_FAILURE,
    buildContractError: error,
    buildContractLoading: false,
  };
};

const prviewContractOfferSuccess = (response) => {
  return {
    type: documentConstants.PREIVIEW_BUILD_CONTRACT_SUCCESS,
    // buildContractData: response.data.info,
    buildContractLoading: false,
  };
};

const prviewContractOffer = (data) => {
  return (dispatch, getState) => {
    documentDataApi
      .previewContractApi(data)
      .then((response) => {
        dispatch(prviewContractOfferSuccess(response));
      })
      .catch((error) => {
        dispatch(prviewContractOfferFailure(error));
      });
  };
};

// Search buyer
const searchBuyerError = (error) => {
  return {
    type: documentConstants.SEARCH_BUYER_FAILURE,
    searchBuyerError: error,
    searchBuyerLoading: false,
  };
};

const searchBuyerSuccess = (response) => {
  return {
    type: documentConstants.SEARCH_BUYER_SUCCESS,
    searchBuyerData: response.data.info,
    searchBuyerLoading: false,
  };
};

const searchBuyerData = (name) => {
  return (dispatch, getState) => {
    documentDataApi
      .searchBuyerRequest(name)
      .then((response) => {
        dispatch(searchBuyerSuccess(response));
      })
      .catch((error) => {
        dispatch(searchBuyerError(error));
      });
  };
};

// Search buyer
const createCustomerError = (error) => {
  return {
    type: documentConstants.CREATE_CUSTOMER_FAILURE,
    createCustomerError: JSON.stringify(error),
    createCustomerLoading: false,
  };
};

const createCustomerLoading = () => {
  return {
    type: documentConstants.CREATE_CUSTOMER_LOADING,
    createCustomerError: false,
    createCustomerLoading: true,
  }
}

const createCustomerSuccess = (response) => {
  return {
    type: documentConstants.CREATE_CUSTOMER_SUCCESS,
    createCustomerData: response.data.info,
    createCustomerLoading: false,
    againcreatecustomer: true,
  };
};

const clearSuccessDataUserCreate = () => {
  return {
    type: documentConstants.CLEAR_CUSTOMER_CREATE_SUCCESS_DATA,
    createCustomerLoading: true,
  };
};

const createCustomer = ({ data, handleCreateRender, delegateOwnerId, section, selectedCustomer }) => {
  return (dispatch, getState) => {
    dispatch(createCustomerLoading())
    documentDataApi
      .createCustomerRequest({ data, delegateOwnerId })
      .then((response) => {
        dispatch(createCustomerSuccess(response));
        if (handleCreateRender) {
          handleCreateRender(response.data.info._id, response.data.info.fullName)
        }
        if (section === "SAFETY_DRIVE") {
          const updatedSelectedCustomer = {
            zipFileFullname: selectedCustomer?.fullName,
            personId: response?.data?.info?._id,
            fullName: response?.data?.info?.fullName,
            clientType:  selectedCustomer?.clientType,
            id: selectedCustomer?.id,
            key: selectedCustomer?.key
          };
          documentDataApi.updateSafeTDrivePersonDetailsRequest(updatedSelectedCustomer)
            .then((updateResponse) => {
              message.success(updateResponse?.data?.info?.message || "Client Created Successfully!");
              dispatch(resetCreateCustomer());
              dispatch(accountHandle.getsafeTDriveDetails());
              // dispatch(updateSafetyDriveClientDetailsSuccess({zipId : selectedCustomer?.id, clientType: selectedCustomer?.clientType}));
            })
            .catch((error) => {
              message.error("Failed to update SafeTDrive details. Please try again later!");
            });
        }
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "We encountered an issue while creating a customer. Please try again later!");
        dispatch(createCustomerError(error?.response?.data || "We encountered an issue creating a customer. Please try again later!"));
      });
  };
};


/// update safety drive buyer seller details after client created

const updateSafetyDriveClientDetailsSuccess = ({ zipId, clientType }) => {
  return {
    type: documentConstants.UPDATE_SAFETY_DRIVE_CLIENT_DETAILS,
    updateSafetyDriveClientDetails: true,
    zipId,
    clientType
  };
};


// Search Buyer results
const getPropertySearchError = (error) => {
  return {
    type: documentConstants.SEARCH_PROPERTY_FAILURE,
    getPropertySearchError: error,
    getPropertySearchLoading: true,
    backKey: true,
  };
};

const getPropertySearchSuccess = (response) => {
  return {
    type: documentConstants.SEARCH_PROPERTY_SUCCESS,
    getPropertySearchData: response.data.info.data,
    propertySearchNextPage: response.data.info.hasNextPage,
    propertySearchPreviousPage: response.data.info.hasPreviousPage,
    getPropertySearchLoading: false,
    backKey: true,
  };
};

const getPropertySearchLoading = (response) => {
  return {
    type: documentConstants.SEARCH_PROPERTY_LOADING,
    getPropertySearchLoading: response,
  };
};

const getPropertySearch = (data, demoMode,skip, limit) => {
  return (dispatch, getState) => {
    dispatch(getPropertySearchLoading(true));
    documentDataApi
      .getPropertySearchRequest(data,demoMode, skip, limit)
      .then((response) => {
        dispatch(getPropertySearchSuccess(response));
      })
      .catch((error) => {
        dispatch(getPropertySearchError(error));
      });
  };
};

// DRAFT DOCUMENTS API Calling

const getDraftDocumentsLoading = (error) => {
  return {
    type: documentConstants.DRAFT_DOCUMENTS_LOADING,
  };
};
const getDraftDocumentsFailure = (error) => {
  return {
    type: documentConstants.DRAFT_DOCUMENTS_FAILURE,
    getDraftFailure: error,
    getDraftLoading: false,
  };
};

const getDraftDocumentsSucess = (response) => {
  return {
    type: documentConstants.DRAFT_DOCUMENTS_SUCCESS,
    getDraftDocumentData: response,
    getDraftLoading: false,
  };
};

const getDraftDocumentData = (data) => {
  return (dispatch, getState) => {
    dispatch(getDraftDocumentsLoading());
    documentDataApi
      .draftDocumentsApi(data)
      .then((response) => {
        dispatch(getDraftDocumentsSucess(response.data.info));
      })
      .catch((error) => {
        dispatch(getDraftDocumentsFailure(error));
        if (error?.response?.status === 401) {
          localStorage.removeItem("userAuth");
          window.location.reload();
        }
      });
  };
};
const addPropertyLoading = () => {
  return {
    type:documentConstants.ADD_PROPERTY_LOADING
  }
}

const addPropertyError = (err) => {
  return {
    type:documentConstants.ADD_PROPERTY_ERROR
  }
}

const addPropertySuccess = (data) => {
  return {
    type:documentConstants.ADD_PROPERTY_SUCCESS,
    addedProperty:data
  }
}

const resetAddProperty = () => {
    return {
      type:documentConstants.RESET_ADD_PROPERTY,
      addedProperty:[]
    }
  }

const addListingProperty = ({data, setFieldIsEmpty, fieldEmpty, redirectFromClient}) => {
  return (dispatch) => {
    dispatch(appStatusChange.loadOpacityLoader(true));
    documentDataApi.addListingProperty(data).then((response) => {
      dispatch(appStatusChange.loadOpacityLoader(false));
      redirectFromClient.form.setFieldsValue({price: response.data.info?.listingPrice || ""})
      setFieldIsEmpty({
        ...fieldEmpty,
        property: response.data.info,
        propertyType: response.data.info?.propertyType || "",
        priceType: response.data.info?.listingPrice || ""
      })
      message.success("Property Added Successfully!");
    })
    .catch((err) => {
      dispatch(appStatusChange.loadOpacityLoader(false));
    })
  };
};


const clearFormProperty = (data) => ({
  type: documentConstants.CLEAR_FORM_PROPERTY,
  datas: data,
});

const clearCustomerData = () => ({
  type: documentConstants.RESET_CUSTOMER_DATA,
});
const resetCreateCustomer = () => ({
  type: documentConstants.RESET_CREATE_CUSTOMER,
});

const onFocusData = (object) => ({
  type: documentConstants.ON_FOCUS_DATA,
  onFocusData: object,
});

const setCorpState = (payload) => ({
  type: documentConstants.UPDATE_CORP_DATA,
  payload,
});

const setCorpType = (isCorp) => ({
  type: documentConstants.SET_CORP_TYPE,
  isCorp
})

const resetCorpState = () => ({
  type: documentConstants.RESET_CORP_DATA,
});

const onBackbuttonClick = () => ({
  type: documentConstants.PROPERTY_SEARCH_BACK_BUTTON,
});

const cbsrContractID = (payload) => ({
  type: documentConstants.CBSR_CONTRACT_ID,
  payload: payload,
});

const getMlsLoading = () => {
    return {
      type:documentConstants.SEARCH_PROPERTY_LOADING
    }
  }

  const getMlsError = (err) => {
    return {
      type:documentConstants.SEARCH_PROPERTY_FAILURE
    }
  }

  const getMlsSuccess = (response) => {
    return {
      type:documentConstants.SEARCH_PROPERTY_SUCCESS,
      getPropertySearchData: response.data,
      propertySearchNextPage: response.data.hasNextPage,
      propertySearchPreviousPage: response.data.hasPreviousPage,
      getPropertySearchLoading: false,
      backKey: true,
    }
  }

  const getMLSPropertySearch = (data) => {
    return (dispatch) => {
      dispatch(getMlsLoading())
      documentDataApi.getMLSsearchReq(data).then((response) => {
        dispatch(getMlsSuccess(response.data.info));
      })
      .catch((err) => {
        dispatch(getMlsError(err))
      })
    };
  };

  const resetSearchProperties = () => {
    return{
        type:documentConstants.RESET_SEARCH_PROPERTIES,
        getPropertySearchData:[],
    }
  }

  // Search Owners results
const getOwnerSearchError = (error) => {
    return {
      type: documentConstants.GET_OWNERS_DETAILS_ERROR,
    };
  };

  const getOwnerSearchSuccess = (response) => {
    return {
      type: documentConstants.GET_OWNERS_DETAILS_SUCCESS,
      ownersData: response?.data?.info?.values,
    };
  };

  const getOwnerSearchLoading = (response) => {
    return {
      type: documentConstants.GET_OWNERS_DETAILS_LOADING,
    };
  };

  const getOwnerSearch = (data) => {
    return (dispatch, getState) => {
      dispatch(getOwnerSearchLoading(true));
      documentDataApi
        .getOwnerSearchRequest(data)
        .then((response) => {
          dispatch(getOwnerSearchSuccess(response));
        })
        .catch((error) => {
          dispatch(getOwnerSearchError(error));
        });
    };
  };

  const setpdfImportState = (payload) => {
    return {
      type: documentConstants.SET_PDF_IMPORT_STATE,
      payload,
    };
  };

export const documentAction = {
  getDocumentData,
  searchBuyerData,
  createCustomer,
  clearCustomerData,
  onFocusData,
  getPropertySearch,
  onBackbuttonClick,
  getContractOffer,
  cbsrContractID,
  buildContractOffer,
  prviewContractOffer,
  getDraftDocumentData,
  draftDocumentURl,
  clearFormProperty,
  setCorpState,
  setCorpType,
  resetCorpState,
  urlImport,
  clearUrlData,
  createRoleRequest,
  resetCreateCustomer,
  urlImportErrorClear,
  clearSuccessDataUserCreate,
  resetOfferData,
  pdfError,
  addListingProperty,
  getMLSPropertySearch,
  resetSearchProperties,
  getOwnerSearch,
  setpdfImportState,
  urlImportSuccess,
  pdfImportLoading,
  resetAddProperty,
};

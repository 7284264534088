import React, { useMemo, useState } from "react";
import { encryptStr, encryptUrlParams } from "../../Common/utils/extraFunctions";
import {
  FileDoneOutlined,
  CalendarOutlined,
  CloudDownloadOutlined,
  FolderOutlined,
  RobotOutlined,
  FormOutlined,
  EyeOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {  Tooltip } from "antd";

import styles from "../styles/panelComp.module.css";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PropertyDocsModal } from "./PropertyDocsModal";
import { ClientPortaModal } from "../../Rtd/component/ClientPortaModal";
import NotesDrawer from "./NotesDrawer";
import { IoSendSharp } from "react-icons/io5";
import { SignableAndReadOnly } from "./SignableAndReadOnly";
import { PropertyImgs } from "./PropertyImgs";
import { transactionLaneDataHandle } from "../../Rtd/state/actions";
import { OfferImportModal } from "./OfferImportModal";
import {AddEmailModalRtd} from "../../Rtd/component/AddEmailModalRtd";
import { checkIfDisabled, disabledTransactionText } from "../../Common/commondata/commonData";
import EditAgentsModal from "./EditAgentsModal";
import axios from "axios";
import { getAgentsArrayFromIndividualTxnData } from "./helper";



const PanelComp = ({ item, section, filterSort }) => {
  const delegate =  (section === "DELEGATE");
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const agentDetails = item?.currentAgentId;
  const [isModalOpen, setIsModalOpen] = useState({
    propertyDocs: false,
    offerImport: false,
    noteDrawervisible:false,
    isEmailModalVisible:false,
    isAllEmailsModalOpen:false,
  });

  // states for modals need to be optimized later
  const [showmodal, setshowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getIndividualTransactionData } = useSelector((state) => state.rtdTransactionLane);
  // Client Portal modal
  const hanldeClickOfferImport = () => {
    setIsModalOpen({ offerImport: true });
  };
  // Client Portal modal
  const handledataClick = () => {
    let data;
    if(item?.isOffer || item?.isDraftOffer){
      data = item?.newTransactionData?.Buyers?.filter((el) => (!el?.personId || el?.personId === null));
    }else{
      data = item?.newTransactionData?.Sellers?.filter((el) => (!el?.personId || el?.personId === null));
    }
    if (data?.length) {
      setIsModalOpen({...isModalOpen,isAllEmailsModalOpen:true})
      }else{
      setIsModalOpen({...isModalOpen,isAllEmailsModalOpen:false})
      setshowModal(true);
      }
  };

  // Notes drawer
  const closeDrawerOfNotes = () => {
    setIsModalOpen({...isModalOpen,noteDrawervisible:false});
  };

  //Property Doc Method
  const handlePropertyDocClick = () => {
    setIsModalOpen({ propertyDocs: true });
  }


  //Task And Calendar Method
  const handleTaskAndCalendarClick = () => {
    if (delegate) {
      const encryptedOwnerId = encryptUrlParams(agentDetails?._id);
      if(item?.isProperty || item?.isErts){
        history.push(`/account/delegates/transactions/sTask/${encryptStr(item?.offerId)}?ref=${encryptedOwnerId}`);
      }
      else{
        history.push(`/account/delegates/transactions/bTask/${encryptStr(item?.offerId)}?ref=${encryptedOwnerId}`);
      }
    }
    else{
      if(item?.isProperty || item?.isErts){
        history.push({
            pathname: `/transactions/sTask/${encryptStr(item?.offerId)}`,
        });
      }else{
        history.push({
          pathname: `/transactions/bTask/${encryptStr(item?.offerId)}`,
        });
      }
    }
  }


  const handleDashboardRedirection = () => {
    if (delegate) {
      const encryptedOwnerId = encryptUrlParams(agentDetails?._id);
      if (item?.isProperty) {
      history.push(`/account/delegates/transactions/sDashboard/${encryptStr(item.offerId)}?ref=${encryptedOwnerId}`);
      }
      else if (item?.isOffer || item?.isDraftOffer) {
        const encryptedOwnerId = encryptUrlParams(agentDetails?._id);
        history.push(`/account/delegates/transactions/bDashboard/${encryptStr(item.offerId)}?ref=${encryptedOwnerId}`);
      }
    }
    else{
      if(item?.isProperty){
        history.push(`/transactions/sDashboard/${encryptStr(item.offerId)}`);
      }
      else if(item?.isOffer || item?.isDraftOffer){
        history.push(`/transactions/bDashboard/${encryptStr(item.offerId)}`);
      }
    }
  }

  const handleSendDocument = () => {
   const role=item?.isProperty || item?.isErts ? "SELLER" : "BUYER"
    let unmounted = {
      value: false
    };
    let source = axios.CancelToken.source();
      const delegateSide = section === "DELEGATE" && role;
      dispatch(transactionLaneDataHandle.getIndividualTransaction({ offerId: item?.offerId, ertsId: item?.persistId, propertyId: item?.propertyId, setLoading, unmounted, source, delegateSide, delegateOwnerId: delegateSide && item?.currentAgentId?._id }));
     setIsModalOpen({...isModalOpen,isEmailModalVisible:true, sendMultipleDoc:true, isclearTransactionLaneData:true})
  }

  const allBuyerAgentData = useMemo(() => {
    let agents=[]
    agents = getAgentsArrayFromIndividualTxnData({
      agentType:'SELLER_AGENT',
      txnData: getIndividualTransactionData,
    });
   return agents;
  }, [getIndividualTransactionData]);

  const allSellerAgentData = useMemo(() => {
    let agents=[]
    agents = getAgentsArrayFromIndividualTxnData({
      agentType: 'BUYER_AGENT',
      txnData: getIndividualTransactionData,
    });
    return agents
  }, [getIndividualTransactionData]);
  const detailsData = {
    ...(item?.offerId ? { offerId: item?.offerId } : {}),
    ...(item?.persistId ? { ertsId: item?.persistId } : {}),
    ...(item?.propertyId ? { propertyId: item?.propertyId } : {}),
    ...(item?.thirdpartyId ? { thirdpartyId: item?.thirdpartyId } : {}),
    accessibleFor: (item?.isErts || item?.isProperty) ? "SELLER_AGENT" : "BUYER_AGENT",
    ...(delegate ? {delegateSide: (item.isProperty || item?.isErts)? "SELLER" : "BUYER", delegateOwnerId: agentDetails?._id} : {}),
  };

  const urlShortningForDraft = () => {
    let buildData = {
      builtForId: agentDetails?._id,
      key: agentDetails?._id,
      builtForSection: "DOCUMENT",
      signable: true,
      openFor: "SELLER_AGENT",
      contractType:  "ERTS",
      contractId: item?.contractId,
      documentId: item?._id,
      offerId: item?.offerId,
      token: localStorage.getItem("userAuth"),
      ...(delegate ? {delegateOwnerId: agentDetails?._id} : {})
    };
    // console.log("buildData", buildData);
    dispatch(transactionLaneDataHandle.getDraftDocumentData(buildData, location?.pathname));
  };

  const checkForDisable = checkIfDisabled({ openFor: (item?.isProperty || item?.isErts) ? "SELLER" : "BUYER", actualPropertyStatus: item?.actualPropertyStatus, buyingStatus: item?.actualOfferStatus, offerStatus: item?.status });

  return (
    <div className={styles.panelCompBox}>
      {/* various Modals here ...   */}
      {/* offerImport Modal  */}
      {isModalOpen?.offerImport && (
        <OfferImportModal
          isModalOpen={isModalOpen}
          section={section}
          setIsModalOpen={setIsModalOpen}
          selectedData={item}
        />
      )}
      {/* PropertyDocs Modal  */}
      {isModalOpen?.propertyDocs && (
        <PropertyDocsModal
          isModalOpen={isModalOpen}
          section={section}
          setIsModalOpen={setIsModalOpen}
          role={(item?.isProperty || item?.isErts) ? "SELLER" : "BUYER"}
          item={item}
        />
      )}

      {/* Send Document Modal */}
      <SignableAndReadOnly
        role={item?.isProperty || item?.isErts ? "SELLER" : "BUYER"}
        source={item?.isProperty || item?.isErts ? "SELLING_DRAFT" : "BUYING_DRAFT"}
        section={section}
        nestedModalOpen={isModalOpen}
        setNestedModalOpen={setIsModalOpen}
        selectedData={item}
        docSelected={false}
        documentId={item?.isProperty || item?.isErts ? item?._id : item?.CBSRId}
        allBuyerAgentData={allBuyerAgentData}
        allSellerAgentData={allSellerAgentData}
        delegateOwnerId={delegate && item?.currentAgentId?._id}
      />

      {/* Client portal Modal */}
      {showmodal && !isModalOpen.isEmailPresent ? (
        <ClientPortaModal
          personId={item?.currentAgentId?._id}
          showmodal={showmodal}
          setshowModal={setshowModal}
          selectedData={(item?.isOffer || item?.isDraftOffer) ? item.buyerIds : (item?.acceptedOfferSellers || item?.sellerIds)}
          Role={(item?.isOffer || item?.isDraftOffer) ? "BUYER" : "SELLER"}
          offerid={item?.offerId}
          ertsId={item?.persistId}
          thirdpartyIdDoc={item?.thirdpartyId}
        />
      ) : <AddEmailModalRtd modalVisible={isModalOpen} setModalVisible={setIsModalOpen} selectedData={item} seller={(item?.isOffer || item?.isDraftOffer) ? "BUYER" : "SELLER"} filterSort={filterSort} delegateSide={delegate && ((item.isProperty || item?.isErts) ? "SELLER" : "BUYER")}/>}

      {/* Notes Drawer  */}
      {isModalOpen?.noteDrawervisible &&
     ( <NotesDrawer
        addressForNotes={item && item?.address}
        noteDrawervisible={isModalOpen?.noteDrawervisible}
        closeDrawerOfNotes={closeDrawerOfNotes}
        detailsData={detailsData}
      />)}
      {/* Agent Edit Modal  */}
      {isModalOpen?.editAgentModalVisible &&
     ( <EditAgentsModal
        item={item}
        filterSort={filterSort}
        isModalOpen={isModalOpen}
        section={section}
        setIsModalOpen={setIsModalOpen}
      />)}

      {/* heading for address  */}
      <div className={styles.transactionDetail}>
        <div>
          <PropertyImgs currentlySelectedData={item} />
        </div>
        <div className={styles.secondDetail}>
          {
            (item?.isProperty || item?.isOffer || item?.status === "DRAFT") && <div
              className={styles.secondDetailList}
              style={(item?.offerId) ? {} : {cursor: "not-allowed", color: "rgb(184, 184, 184)"}}
              {...((item?.offerId) ? {onClick: handleDashboardRedirection} : {})}
            >
              <FileDoneOutlined />
              <span>Transaction Dashboard</span>
            </div>
          }
           {item?.isProperty && (
            <Tooltip placement="top" title={"Import offer from PDF document"}>
              <div className={styles.secondDetailList} {...(checkForDisable?.disabled ? {onClick: disabledTransactionText} : {onClick: hanldeClickOfferImport})}>
                <CloudDownloadOutlined />
                <span>Offer Import</span>
              </div>
            </Tooltip>
          )}
          {
             <div
                className={styles.secondDetailList}
                onClick={handlePropertyDocClick}
              >
                <FolderOutlined />
                <span>
                  Property Docs
                </span>
              </div>
          }
          {
           (item?.isProperty || (((item?.status === "ACCEPTED") || (item?.status === "TERMINATED")) && item?.isOffer)) && <div className={styles.secondDetailList}
           style={(item?.offerId || item?.isOffer ) ? {} : {cursor: "not-allowed", color: "rgb(184, 184, 184)"}}
                {...(((item?.propertyStatus === "ACCEPTED") || item?.isOffer ) ? {onClick: handleTaskAndCalendarClick} : {})}
              >
                <CalendarOutlined />
                <span>Task/Calendar</span>
              </div>
          }

          <div className={styles.secondDetailList} onClick={handledataClick}>
            <RobotOutlined />
            <span>Client Portal</span>
          </div>

          {/* {(item?.isErts || item?.isDraftOffer) &&  */}
          <div className={styles.secondDetailList} onClick={handleSendDocument}>
            <IoSendSharp/>
            <span>Send Document</span>
          </div>
          {/* || item?.isDraftOffer */}
          {(item?.isErts ) && <div className={styles.secondDetailList} onClick={urlShortningForDraft}>
            <EyeOutlined/>
            <span>View Document</span>
          </div>}
          {<div className={styles.secondDetailList} onClick={() => {setIsModalOpen({...isModalOpen,noteDrawervisible:true});}}>
            <FormOutlined />
            <span>Notes</span>
          </div>}
          {item?.isProperty && !item?.offerId && <div className={styles.secondDetailList} onClick={() => {setIsModalOpen({...isModalOpen, editAgentModalVisible:true});}}>
            <EditOutlined />
            <span>Edit Broker-Agents</span>
          </div>}
        </div>

        <div className={styles.thirdDetail}>
          {/* <img
            className={styles.noteImage}
            src={NotesIcon}
            alt="notes"
            onClick={() => {
                setIsModalOpen({...isModalOpen,noteDrawervisible:true});
            }}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default PanelComp;

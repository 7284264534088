import React from 'react'
import { ClientNavbar } from '../App/components/ClientNavbar';
import { useLocation } from "react-router-dom";
import { Calendar } from '../PropertyComp/components/Calendar';
import { decryptQuery } from '../Common/commondata/commonData';
import styles from "./styles/ClientScreen.module.css"
import { Tabs } from 'antd';
const { TabPane } = Tabs;


function ReadOnlyClientScreen() {
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const urlId = decryptQuery(query.get('urlId'));
    const urlData = decryptQuery(query.get('urlData'));

    return (
        <div style={{overflow: 'hidden'}}>
            <ClientNavbar source={'ReadOnlyClientScreen'} urlData={urlData} />
            <Tabs defaultActiveKey="1" centered size='small' style={{overflow: 'hidden'}}>
                <TabPane tab={<div>Calendar</div>} key="1">
                    {urlData?.fullAddress && <div className={styles.readOnlyFullAddress}>{urlData?.fullAddress}</div>}
                    <Calendar agentType="SELLER" viewType={'month'} screen={"ReadOnlyClientScreen"}  offerId={urlData?.offerId} />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default ReadOnlyClientScreen
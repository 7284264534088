/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Modal, Upload, message, Avatar,DatePicker, Select, Tooltip  } from 'antd';
import { PlusCircleFilled, DeleteTwoTone, EditTwoTone,LinkOutlined  } from "@ant-design/icons";
import React, {  useEffect, useRef, useState } from 'react';
import { FaRegFilePdf } from 'react-icons/fa'
import { AiOutlineClose } from 'react-icons/ai'
import { FcOpenedFolder } from 'react-icons/fc'
import { ImFolderDownload } from 'react-icons/im'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { Loading } from '../../Common/components/Loading';
import { EmptyData } from '../../Common/components/EmptyData';
import JSZip from 'jszip';
import "../styles/zipUpload.css"
import { accountHandle } from '../state/actions';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Option } from 'antd/lib/mentions';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import styles from "../styles/ZipUpload.module.css"
import { capitalizeFullname, capitalizeName, getInitials, splitFullName } from '../../Common/utils/extraFunctions';
import { CreateCustomerDrawer } from '../../DocumentSection/components/CreateCustomerDrawer';
import { documentAction } from '../../DocumentSection/state/actions';
import { clientAction } from '../../Client/state/actions';
import { ClientList } from '../../Client/components/ClientList';
import { v4 as uuidv4 } from "uuid";


export default function ZipUpload({ rowsPerPage }) {
  const [uploadFile, setUploadFile] = useState("");
  // const [selectedcbsr, setSelectedcbsr] = useState(false);
  // const [uploadedData, setUploadedData] = useState([]);
  // let [searchedFiles, setSearchedFiles] = useState([]);
  const [showViewFileModal, setShowViewFileModal] = useState(false);
  const [loading, setLoading] = useState(false);
  let [fileText, setFileText] = useState();
  // const [pdfFilesToOpen, setPdfFilesToOpen] = useState([])
  const pdfFilesToOpen = useRef([])
  // const [pdfFilesInsideFolder, setPdfFilesInsideFolder] = useState([]);
  const pdfFilesInsideFolder = useRef([]);
  const [pdfs, setPdfs] = useState(true);
  const [showPdfLikeLocalDrive, setShowPdfLikeLocalDrive] = useState(false);
  const [searchedTextInComponent, setSearchedTextInComponent] = useState("")
  const [dateSearchInComponent, setDateSearchInComponent] = useState({ startingDate: null, endingDate: null })
  const [sortByInComponent, setSortByInComponent] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [editState, setEditState] = useState({ field: "", value: "", id: "", newEntry: { fullName: "", personId: null, key: "" }});
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({ id: "", fullName: "", clientType: "", firstName: "", middleName: "", lastName: "", key: "" });
  const [showClientListModal, setShowClientListModal] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(true);
  const [page, setPage] = useState([1]);
  const [mergeIndex, setMergeIndex] = useState(null);

  const {
    safeTDriveLoading,
    safeTDriveData,
    createdSafeTDriveData,
    safeTDriveTotalData,
    pageNumberInZip,
    AwsBufferDataSafeTDrive,
    searchedText,
    dateSearch,
    sortBy,
    safeTDriveError
  } = useSelector((state) => state.account);
  const { updateSafetyDriveClientDetails, zipId, clientType } = useSelector(
    (state) => state.documentSection.createCustomerReducer
  );
  let dispatch = useDispatch();
   
  useEffect(() => {
    setSearchedTextInComponent(searchedText);
    setDateSearchInComponent(dateSearch)
    setSortByInComponent(sortBy)
  }, []);

  const history = useHistory();
  React.useLayoutEffect(() => {
    const unlisten = history.listen(() => {
      dispatch(accountHandle.updateSearchedText(""))
      dispatch(accountHandle.updateDateSearchInZip({ startingDate: null, endingDate: null }))
      dispatch(accountHandle.updateSortByInZip(""))
    });
    return () => {
      unlisten();
    };
  }, []);

  // modal for preview Name and file size and other details of zip
  const showModalFileView = () => {
    setShowViewFileModal(true)
  };

  // Create safe
  const handleOkFileView = () => {
    setShowViewFileModal(false);
    dispatch(accountHandle.createsafeTDriveDetails(uploadFile));
  };

  useEffect(() => {
    if (createdSafeTDriveData?.fileName === "NO CBSR FOUND" || createdSafeTDriveData?.fileUploaded || createdSafeTDriveData?.totalFile) {
      handleCancelFileView(false)
      let totalCBSR = createdSafeTDriveData?.totalFile
      let fileUploaded = createdSafeTDriveData?.fileUploaded
      if (fileUploaded) {
        message.error("You have already uploaded this file");
        return
      } else {
        message.success(`Uploaded Sucecssfully! | ${createdSafeTDriveData?.totalFile} CBSR Found`);
      }
    }
  }, [createdSafeTDriveData]);


  const handleCancelFileView = () => {
    setShowViewFileModal(false)
  };

  // For showing pdf file like local
  const handleCancelShowLocalFile = () => {
    setShowPdfLikeLocalDrive(false);
    dispatch(accountHandle.removeSafeTDrivebufferDataFromRedu());
  }

  let supportedFormats = "image"
  function bytesToSize(bytes) {
    return
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  function beforeUpload(file) {
    return true
    // if (supportedFormats.includes("image")) {
    //   const isJpgOrPng =
    //     file.type === "image/jpeg" || file.type === "image/png";
    //   if (!isJpgOrPng) {
    //     message.error("You can only upload JPG/PNG file!");
    //   }
    //   const isLt5M = file.size / 1024 / 1024 < 5;
    //   if (!isLt5M) {
    //     message.error("Image must smaller than 5MB!");
    //   }
    //   return isJpgOrPng && isLt5M;
    // }

    // if (supportedFormats.includes("pdf")) {
    //   const isPdf = file.type === "application/pdf";
    //   if (!isPdf) {
    //     message.error("You can only upload PDF file!");
    //   }
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   if (!isLt2M) {
    //     message.error("File must be smaller than 2MB!");
    //   }
    //   return isPdf && isLt2M;
    // }
  }

  let handleCustome = (event) => {
    setFileText(event?.file?.name);
    const file = event.file;
    const formData = new FormData();
    formData.append('file', event?.file);
    formData.append('filename', event?.file?.name);

    setUploadFile(formData);
    showModalFileView()
  };

  const onSearch = (e) => {
    setSearchedTextInComponent("")
    // if (!e) {
    //   handleClear();
    //   return
    // }
  }
 
// Handling Search
  const handleSearch = (e) => {
    setLoading(true);
    let searchByDate = dateSearchInComponent;

    if (searchByDate.startingDate || searchByDate.endingDate) {
      if (!(searchByDate.startingDate)) {
        message.error("Please enter Start Date !");
        setLoading(false)
        handleClear();
        return
      } else if (!(searchByDate.endingDate)) {
        message.error("Please enter End date !");
        setLoading(false)
        handleClear();
        return
      }

    }
    if (searchedText.current == '' && (!searchByDate.startingDate || !searchByDate.endingDate)) {
      setLoading(false)
      handleClear();
      message.error("Please Enter Search Criteria");
      return
    }

    // let searchCriteria ={searchText:searchedTextInComponent}
    let searchCriteria;
    if (searchByDate.startingDate && searchByDate.endingDate) {

      searchCriteria = {
        searchText: searchedText,
        ...searchByDate
      };
    } else {
      searchCriteria = {
        searchText: searchedTextInComponent,
      };
    }

    const queryString = Object.entries(searchCriteria)
      ?.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      ?.join('&');


    // dispatch(accountHandle.searchsafeTDriveDetails(queryString,byKey,byAccDce))

    if (sortByInComponent) {
      let sortByCriteria = sortByInComponent.split(" ")
      let byKey = sortByCriteria[0]
      let byAccDce = sortByCriteria[1]
      dispatch(accountHandle.searchsafeTDriveDetails(queryString, byKey, byAccDce))
    } else {
      dispatch(accountHandle.searchsafeTDriveDetails(queryString))
    }
    dispatch(accountHandle.updateSearchedText(searchedTextInComponent));
    dispatch(accountHandle.updateDateSearchInZip(dateSearchInComponent))
  }

  const handleClear = () => {
    dispatch(accountHandle.getsafeTDriveDetails(pageNumberInZip, 25));
    dispatch(accountHandle.updateSearchedText(""))
    dispatch(accountHandle.updateDateSearchInZip({ startingDate: null, endingDate: null }))
    dispatch(accountHandle.updateSortByInZip(""))
  };

  let zipFileName = [];
  let insideFolderArray = []
  // Function to convert zip buffer data to plf
  const outerFunc = async () => {
    const getPdfBuffer = async () => {
      const zipData = new Uint8Array(AwsBufferDataSafeTDrive);
      const unzipNestedZip = async (zip) => {
        for (const filename in zip.files) {
          const file = zip.files[filename];
          if (file.name.endsWith(".zip")) {
            const nestedZipData = await file.async("arraybuffer");
            const nestedZip = new JSZip();
            const nestedZipContents = await nestedZip.loadAsync(nestedZipData);
            await unzipNestedZip(nestedZipContents);
            return
          } else {
            const content = await file.async("uint8array");
            let fileName = file.name
            if (fileName.includes("/")) {
              let name = fileName.split("/");
              let foldereName = name[0]
              let filenamePdf = name[name?.length - 1]
              insideFolderArray.push({ fileName: filenamePdf, bufferData: content, foldereName });
            } else {
              zipFileName.push({ fileName: file.name, bufferData: content })
            }
          }
        }
      };

      const zip = new JSZip();
      const zipContents = await zip.loadAsync(zipData);
      await unzipNestedZip(zipContents);
      // setLoading(false);
    }
    if (AwsBufferDataSafeTDrive !== "") {
      await getPdfBuffer();
      pdfFilesToOpen.current = zipFileName;
      pdfFilesInsideFolder.current = insideFolderArray;
      setShowPdfLikeLocalDrive(true);
      setLoading(false);
      insideFolderArray = [];
      zipFileName = [];
    }
  }

  useEffect(() => {
    outerFunc()
  }, [AwsBufferDataSafeTDrive])

  const handlePdfViewerClick = async (e) => {
    let awsUrl = { url: e?.fileLink }
    setLoading(true);
    dispatch(accountHandle.getSafeTDrivebufferData(awsUrl))
    return
    let link = e?.fileLink
    // let response = await fetch('https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/63fdd072858258c667ce0b28/pdf_doc/19Changhoon_Kim____May252023_0810.zip')
    // const zipData = await response.arrayBuffer();

    let response = ""
    let accessTokan = localStorage.getItem("userAuth");
    const postResponse = await axios.post(
      `http://localhost:8000/v1/getAwsBufferData`,
      { ...awsUrl },
      {
        headers: {
          authorization: `bearer ${accessTokan}`,
        },
      }
    );
    response = postResponse.data.info.data;

    const zipData = new Uint8Array(response);

    const unzipNestedZip = async (zip) => {
      for (const filename in zip.files) {
        const file = zip.files[filename];
        if (file.name.endsWith(".zip")) {
          const nestedZipData = await file.async("arraybuffer");
          const nestedZip = new JSZip();
          const nestedZipContents = await nestedZip.loadAsync(nestedZipData);
          await unzipNestedZip(nestedZipContents);
          return
        } else {
          const content = await file.async("uint8array");
          let fileName = file.name
          if (fileName.includes("/")) {
            let name = fileName.split("/");
            let foldereName = name[0]
            let filenamePdf = name[name?.length - 1]
            insideFolderArray.push({ fileName: filenamePdf, bufferData: content, foldereName });
          } else {
            zipFileName.push({ fileName: file.name, bufferData: content })
          }
        }
      }
    };

    const zip = new JSZip();
    const zipContents = await zip.loadAsync(zipData);
    await unzipNestedZip(zipContents);
    setLoading(false);
  };

  // function to open all pdf in modal
  const handleOpenZip = async (e) => {
    await handlePdfViewerClick(e);
  }

  // function to open the pdf
  const handleOpenPdfInTab = (el) => {
    let content = (el?.bufferData)
    const blob = new Blob([content], { type: "application/pdf" });
    const fileUrl = URL.createObjectURL(blob);
    window.open(fileUrl);
  }
  // Handling date search
  const dateFormat = "MM-DD-YYYY";

  const onChangeEnddate = (date, dateString) => {
    if (date === null) {
      setDateSearchInComponent({
        ...dateSearchInComponent,
        endingDate: null
      })
      // dateSearch.current = { ...dateSearch.current, endingDate: null }
      // handleClear()
    } else {
      let finaldate = getDateFromString(date?._d);
      setDateSearchInComponent({
        ...dateSearchInComponent,
        endingDate: finaldate
      })
      // dateSearch.current = { ...dateSearch.current, endingDate: finaldate }
    }
  }

  const onChangeStartdate = (date, dateString) => {
    if (date === null) {
      setDateSearchInComponent({
        ...dateSearchInComponent,
        startingDate: null
      })
      // dateSearch.current = { ...dateSearch.current, startingDate: null }
      // handleClear()
    } else {
      let finaldate = getDateFromString(date?._d);
      setDateSearchInComponent({
        ...dateSearchInComponent,
        startingDate: finaldate
      })
      // dateSearch.current = { ...dateSearch.current, startingDate: finaldate }
    }
  }

  const getDateFromString = (dateStr) => {
    const dateString = dateStr
    const dateObject = new Date(dateString);
    const month = dateObject.getMonth() + 1;
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();
    const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
    return formattedDate
  }
  const handleClearDate = () => {
    // handleClear()
  }

  const totalPages = Math.ceil(safeTDriveTotalData / rowsPerPage);
  // NOw using now will use in future
  const getCurrentPageData = () => {
    const startIndex = (pageNumberInZip - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return safeTDriveData?.slice(startIndex, endIndex);
  };

  const handlePageChange = (page) => {
    dispatch(accountHandle.handlePaginantionInZipUpload(page));
    dispatch(accountHandle.getsafeTDriveDetails(page, 25))
  };

  const goToPreviousPage = () => {
    let finalPage = pageNumberInZip - 1
    dispatch(accountHandle.handlePaginantionInZipUpload(finalPage));
    if (sortBy.current) {
      dispatch(accountHandle.getsafeTDriveDetails(finalPage, 25, sortBy.current.byKey, sortBy.current.byAccDce))
    } else {
      dispatch(accountHandle.getsafeTDriveDetails(finalPage, 25))
    }
  };

  const goToNextPage = () => {
    let finalPage = pageNumberInZip + 1
    dispatch(accountHandle.handlePaginantionInZipUpload(finalPage));
    if (sortBy.current) {
      dispatch(accountHandle.getsafeTDriveDetails(finalPage, 25, sortBy.current.byKey, sortBy.current.byAccDce))
    } else {
      dispatch(accountHandle.getsafeTDriveDetails(finalPage, 25))
    }
  };

  const handleSorting = (e) => {
    let val = e
    setSortByInComponent(val)
    dispatch(accountHandle.updateSortByInZip(val))
    let searchCriteria = {
      searchText: searchedTextInComponent,
    };
  const queryString = Object.entries(searchCriteria)
    ?.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    ?.join('&');
      let sortByCriteria = val.split(" ")
      let byKey = sortByCriteria[0]
      let byAccDce = sortByCriteria[1]
      dispatch(accountHandle.searchsafeTDriveDetails(queryString, byKey, byAccDce))
  }
  // Handling default Date
  let startDefaultDate;
  let endDefaultDate;
  if (dateSearch?.endingDate) {
    endDefaultDate = moment(dateSearch.endingDate.replace("/", "-"), 'MM-DD-YYYY')
    startDefaultDate = moment(dateSearch.startingDate.replace("/", "-"), 'MM-DD-YYYY')
  }

  const handleDelete = (el) => {
    Modal.confirm({
      title:"Are you sure you want to delete this folder?",
      okText:"Yes",
      cancelText:"No",
      onOk (){
        dispatch(accountHandle.deleteDriveFolder(el?._id))
      },
      onCancel (){},
    })
  }


  const handleEdit = (el, field) => {
    let fieldValue;
    if (['buyer', 'seller'].includes(field)) {
      fieldValue = el[field]?.map(person => ({
        personId: person?.personId || null,
        fullName: person?.fullName || '',
        key: person?.key || ''
      })) || [];
    } else {
      fieldValue = el[field];
    }
    setEditState({ field, value: fieldValue, id: el?._id });
    setShowEditModal(true);
  };

  const updateFieldData = () => {
    const isFileName = editState.field === 'fileName';
    const isEmptyString = editState.value === '';
    let updatedValue;

    if (['buyer', 'seller'].includes(editState.field)) {
      updatedValue = {
        [editState.field]: Array.isArray(editState.value)
          ? editState.value.filter(item => item.fullName.trim() !== '')
          : []
      };
    } else if (isFileName) {
      updatedValue = { [editState.field]: isEmptyString ? '' : editState.value };
    } else if (Array.isArray(editState.value)) {
      const filteredValue = editState.value.filter(item => item.trim() !== '');
      updatedValue = {
        [editState.field]: filteredValue.length ? filteredValue : [],
      };
    } else {
      updatedValue = {
        [editState.field]: isEmptyString ? [] : [editState.value],
      };
    }

    dispatch(accountHandle.updateSafeTDriveDetails({ updatedValue, id: editState.id }));
    setShowEditModal(false);
  };

  const drawerClose = () => {
    setDrawerVisible(false);
    dispatch(documentAction.clearCustomerData());
    dispatch(documentAction.resetCreateCustomer());
  };

  // useEffect(() => {
  //   console.log("iiiiiiioooooooooo", showEditModal, updateSafetyDriveClientDetails);
  
  //   if (updateSafetyDriveClientDetails) {
  //     const matchedItem = safeTDriveData?.find(item => item?._id === zipId);
  //     if (matchedItem) {
  //       console.log("eeeeeeeee");
  //       handleEdit(matchedItem, clientType);
  //     }
  //   }
  // }, [updateSafetyDriveClientDetails, zipId, clientType]);

  const handleInputOrSearchClick = (element,index) => {
    setMergeIndex(index);
    setShowClientListModal(true);
    dispatch(clientAction.clearGetClientData());
    setPage([1]);
  };

  const handleClientListCancel = () => {
    setShowClientListModal(false);
  };

  const handleAdd = () => {
    setShowClientListModal(false);
  }

  // when the user select any client in the clientList
  const handleClientSelected = (selectedClient) => {
    const updatedValue = [...editState.value];
    updatedValue[mergeIndex] = {
      ...updatedValue[mergeIndex],
      fullName: selectedClient?.fullName,
      personId: selectedClient?._id,
    };

    setEditState({ ...editState, value: updatedValue });
    setShowClientListModal(false);
  };

  
  return (
    <div className="App" style={{ height: "calc(100vh - 100px)" }}>
      {(loading || safeTDriveLoading) &&
        <div style={{ position: 'absolute', left: "44%", top: "34%", zIndex: 1 }}>
          <Loading />
        </div>}

      {/* Modal for showing like local Drive */}
      <Modal
        title={<span >
          <AiOutlineArrowLeft
            onClick={() => { setPdfs(true) }}
            style={{
              display: pdfs ? "none" : "flex",
              position: 'absolute',
              top: '10px',
              left: '30px',
              cursor: "pointer"
            }}
          />
          <AiOutlineArrowLeft
            onClick={() => {
              handleCancelShowLocalFile()
              setPdfs(true)
            }}
            style={{
              display: pdfs ? "flex" : "none",
              position: 'absolute',
              top: '10px',
              left: '30px',
              cursor: "pointer"
            }}
          />
          <AiOutlineClose size={20}
            onClick={() => {
              handleCancelShowLocalFile()
              setPdfs(true)
              setLoading(false)
            }}
            style={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              cursor: "pointer"
            }}
          /></span>}
        footer={null}
        open={showPdfLikeLocalDrive}
        // open={true}
        // onCancel={handleCancelShowLocalFile}
        bodyStyle={{
          height: "calc(100vh - 155px)",
          top: -160
        }}
        style={{
          marginTop: "-50px"
        }}
        width={1200}
        closable={false}

      >
        <div style={{
          height: "calc(100vh - 210px)",
          overflowY: "auto",
        }}>
          {pdfFilesInsideFolder?.current?.length && pdfs ?
            <p
              onClick={() => {
                setPdfs(false);
              }}
              className='pdfspan'
              style={{
                marginBottom: '-4px',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}
            ><FcOpenedFolder size={20} style={{
              color: "red",
              marginRight: "10px",
              marginBottom: '-4px'
            }} />  {pdfFilesInsideFolder?.current && pdfFilesInsideFolder?.current[0]?.foldereName} </p>
            : ""
          }
          {/* pdfFilesToOpen */}
          {pdfs ? pdfFilesToOpen?.current?.map((el) => el.fileName.endsWith(".pdf") && (
            <li
              onClick={() => handleOpenPdfInTab(el)}
              className='pdfspan'
              style={{
                fontWeight: 500,
                lineHeight: 2,
                cursor: "pointer",

              }}><FaRegFilePdf size={20} style={{
                color: "red",
                marginRight: "10px",
                marginBottom: '-4px'
              }} />
              {el?.fileName}
            </li>
          )) :
            pdfFilesInsideFolder?.current?.map((el) => el.fileName.endsWith(".pdf") && (
              <li
                key={el.fileName}
                onClick={() => handleOpenPdfInTab(el)}
                className='pdfspan'
                style={{
                  fontWeight: 500,
                  lineHeight: 2,
                  cursor: "pointer",

                }}><FaRegFilePdf size={20} style={{
                  color: "red",
                  marginRight: "10px",
                  marginBottom: '-4px'
                }} />
                {el?.fileName}
              </li>
            ))
          }
        </div>

      </Modal>

      {/* Modal For showing result */}
      <Modal title="CBSR File Names"
        open={showViewFileModal}
        onOk={handleOkFileView}
        onCancel={handleCancelFileView}
      >
        <h3><h3>File Name: {fileText}</h3></h3>
      </Modal>

      {/* Modal For Editing Fields */}

      <Modal
        title={
          <div style={{ color: "#65b5ff", textDecoration: 'underline' }}>
            <span>{`${capitalizeFullname(editState?.field)}`}</span>
          </div>
        }
        open={showEditModal}
        onOk={() => { }}
        onCancel={() => {
          setShowEditModal(false);
        }}
        style={{ height: '400px' }}
        footer={[
          <button
            key="save"
            onClick={updateFieldData}
            style={{
              backgroundColor: '#178DFA',
              color: '#fff',
              border: 'none',
              padding: '5px 10px',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Save
          </button>,
        ]}
      >
        <h3>
          {editState?.field === 'address' ? (
            <textarea
              value={editState?.value || ''}
              onChange={(e) => {

                setEditState({
                  ...editState,
                  value: e.target.value,
                });
              }}
              style={{ width: '100%', padding: '5px', fontSize: '14px', minHeight: '100px' }}
            />
          ) : editState?.field === 'buyer' || editState?.field === 'seller' ? (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <input
                  type="text"
                  placeholder={`Please Enter ${capitalizeName(editState?.field)} Name`}
                  value={editState.newEntry?.fullName || ""}
                  onChange={(e) => setEditState({
                    ...editState,
                    newEntry: { ...editState.newEntry, fullName: e.target.value }
                  })}
                  style={{ flex: 1, padding: '5px', fontSize: '14px', border: '1px solid #d3d3d3', marginBottom: '12px' }}
                />
                <Button
                  type='primary'
                  onClick={() => {
                    if (editState.newEntry?.fullName) {
                      setEditState({
                        ...editState,
                        value: [...(editState.value || []), { ...editState.newEntry, personId: null,  key: uuidv4() }],
                        newEntry: { fullName: "", personId: null, key:"" },
                      });
                    }
                  }}
                  disabled={!editState.newEntry?.fullName}
                  style={{
                    marginLeft: '10px',
                    backgroundColor: editState.newEntry?.fullName ? '#178DFA' : '#e0e0e0',
                    color: editState.newEntry?.fullName ? '#f7f7f7' : '#4a4a4a',
                    border: 'none',
                    padding: '4px 8px',
                    cursor: editState.newEntry?.fullName ? 'pointer' : 'not-allowed',
                    marginBottom: "12px",
                  }}
                >
                  {`Add ${capitalizeName(editState?.field)}`}
                </Button>
              </div>
              <div style={{ maxHeight: '300px', overflowY: 'auto', paddingRight: '10px' }}>
                {Array.isArray(editState?.value) &&
                  editState.value?.filter(item => item?.fullName?.trim() !== '')?.map((item, index) => (
                    <div key={index} style={{
                      display: 'flex', alignItems: 'center', marginBottom: '5px',  borderBottom: index < editState.value.length - 1 ? '1px solid #e5e5e5' : 'none',
                      paddingBottom: '10px'
                    }}>
                      <div style={{ width: '25px', marginRight: '10px' }}>
                        {!item.personId ? (
                          <Tooltip title="Create Client">
                            <PlusCircleFilled
                              style={{ color: '#085191', cursor: 'pointer' }}
                              onClick={() => {
                                const { firstName, middleName, lastName } = splitFullName(item?.fullName);
                                setSelectedCustomer({ id: editState?.id, fullName: item.fullName, clientType: editState?.field, key: item?.key, firstName, middleName, lastName });
                                setDrawerVisible(true);
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <div style={{ width: '25px' }}></div>
                        )}
                      </div>
                      <Avatar size={45} style={{ background: '#085191', marginRight: '10px' }}>
                        {getInitials(item?.fullName)}
                      </Avatar>
                      <span style={{ width: '300px' }}>{item.fullName}</span>
                      {!item.personId ? (
                        <Tooltip title="Merge with existing client">
                          <LinkOutlined
                            style={{ color: '#085191', fontSize: "20px", cursor: 'pointer', marginRight: "10px" }}
                            onClick={() => handleInputOrSearchClick(item, index)}
                          />
                        </Tooltip>
                      ) : (
                        <div style={{ width: '30px' }}></div>
                      )}
                      <DeleteTwoTone
                        onClick={() => {
                          const updatedValue = [...editState.value];
                          updatedValue.splice(index, 1);
                          setEditState({ ...editState, value: updatedValue });
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <input
              type="text"
              value={editState?.value}
              onChange={(e) => setEditState({ ...editState, value: e.target.value })}
              style={{ width: '100%', padding: '5px', fontSize: '14px' }}
            />
          )}
        </h3>
      </Modal>

      {/* Add CreateCustomerDrawer component */}
      <CreateCustomerDrawer
        visible={drawerVisible}
        onClose={drawerClose}
        nameData="contact"
        selectedCustomer={selectedCustomer}
        section="SAFETY_DRIVE"
      />

      {/* Modal to merge clients */}

      <Modal visible={showClientListModal} onOk={() => { }} onCancel={handleClientListCancel} footer={false} style={{ marginTop: "-80px" }}>
        <ClientList listSource={"offerSection"} selectClient={handleClientSelected} handleClientListCancel={handleClientListCancel} showSearchInput={showSearchInput} setShowSearchInput={setShowSearchInput} page={page} setPage={setPage}/>
        <Button type="primary" style={{ bottom: 0, left: 175, position: "relative", minWidth: "10vw", marginTop: "20px" }}  onClick={handleAdd}>Add</Button>
      </Modal>

      <div style={{
        display: "flex", justifyContent: 'space-between',
        alignContent: "center", width: "90%", margin: "auto", marginTop: "20px",
      }}>
        <div style={{ visibility: "none" }}></div>

        <div style={{
          display: "flex", justifyContent: 'space-around',
          alignContent: "center",
          height: "30px"
        }}>
          <span style={{ borderRight: 0 }}><Button> Sort By:</Button></span>
          <Select defaultValue={sortBy ? sortBy : "createdDateMili dec"} onChange={handleSorting} style={{ marginRight: "12px", borderLeft: 0 }}>
            <Option key="3" value="createdDateMili dec">Date Descending Default</Option>
            <Option key="2" value="createdDateMili asc">Date Accending</Option>
            <Option key="4" value="fileName asc">File Name a-z</Option>
            <Option key="5" value="fileName dec">File Name z-a</Option>
            <Option key="6" value="accDDate asc">Acceptance Deadline Date Accending</Option>
            <Option key="7" value="accDDate dec">Acceptance Deadline Date Descending </Option>
          </Select>
          <DatePicker
            allowClear
            placeholder="Start date"
            onChange={onChangeStartdate}
            style={{ width: "50%", marginRight: "15px",minWidth:"120px" }}
            format={dateFormat}
            onClear={handleClearDate}
            defaultValue={startDefaultDate}
          // value={dateSearchInComponent.startingDate}
          />
          <DatePicker
            allowClear
            placeholder="End date"
            onChange={onChangeEnddate}
            style={{ width: "50%", marginRight: "15px",minWidth:"120px" }}
            format={dateFormat}
            defaultValue={endDefaultDate}
          // value={dateSearchInComponent.endingDate}
          />
          <Input.Search style={{ marginRight: "20px",minWidth:"130px" }}
            onChange={(e) => {
              setSearchedTextInComponent(e.target.value)
            }
            }
            value={searchedTextInComponent}
            onSearch={handleSearch}
            className="search-input"
            enterButton={false}
            // allowClear
            placeholder="Search" />
          <Button type='primary' style={{ marginRight: "20px" }} disabled = {((searchedText?.length>0) || (dateSearchInComponent?.endingDate || dateSearchInComponent?.startingDate)) ? false : true}
            onClick={() => handleClear()}>Cancel Search</Button>
          <Button type='primary' style={{ marginRight: "20px" }}
           disabled = {((searchedTextInComponent?.length>0) || (dateSearchInComponent?.endingDate && dateSearchInComponent?.startingDate)) ? false : true}
            onClick={handleSearch}>Search</Button>
          <div style={{
            display: "flex", justifyContent: 'space-around',
            alignContent: "center",
            height: "30px"
          }}>
            <p style={{
              marginRight: "20px"
            }}>
              <Upload
                className="borderless-button"
                showUploadList={false}
                accept=".zip,.rar,.7zip"
                customRequest={handleCustome}
                beforeUpload={beforeUpload}
                style={{
                  background: "red",
                  marginRight: "20px",
                  boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                }}
              >
                <Button
                  className="borderless-button"
                  style={{ border: 0 }}
                  icon={<PlusCircleFilled size={30} style={{
                    position: "relative",
                    top: "-2px",
                    fontSize: "30px", color: "#085191"
                  }} />}></Button>
              </Upload></p>
          </div>
        </div>
      </div>

      <div style={{
        margin: "1.5rem",
        padding: "0.5rem",
        // height: "60vh",
        borderRadius: "8px",
        // height: "calc(100vh - 260px)",
         overflowY: "auto"
      }}
      className={styles.safetdrive}
      >
        {safeTDriveData?.length > 0 ? (
          <>
            <table id="myTable"
              style={{
                width: "100%",
                marginTop: "-5px",
                overflowY: "auto",
                overflow: "auto",
                // height: "calc(100vh - 510px)"
              }}
            >
              <thead
                style={{
                  zIndex: 100,
                  height: "50px",
                  width: "100%",
                  position: "sticky",
                  top: -10
                }}
              >
                <tr style={{ backgroundColor: "#2171b9", color: "white" }}>
                  <th style={{ width: "5%" }}>No.</th>
                  <th style={{ width: "22%" }}>File Name</th>
                  <th style={{ width: "22%" }}>Address</th>
                  <th style={{ width: "23%" }}>Buyer</th>
                  <th style={{ width: "23%" }}>Seller</th>
                  <th style={{ width: "5%" }}>Download</th>
                  <th style={{ width: "5%" }}>Delete</th>
                </tr>
              </thead>

              <tbody style={{ width: "100%", }}>
                {safeTDriveData?.map((el, i) => (
                  <tr key={el?._id} style={{ marginTop: "20px", width: "100%" }}>
                    <td
                      style={{
                        fontWeight: "bolder",
                        color: "#77787b",
                        textAlign: "center",
                        width: "5%"
                      }}
                    >
                      {i + 1}
                    </td>
                    <td
                      style={{
                        fontWeight: "bolder",
                        // color: "#77787b",
                        // textAlign: "left",
                        maxWidth: "100px",
                        color: "rgb(76, 186, 242)",
                        cursor: "pointer",
                        // justifyContent: "center",
                        overflow: "hidden",
                        justifyContent: "center",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        position: "relative"
                      }}
                      onClick={() => handleOpenZip(el)}
                    >
                      <span
                        style={{
                          display: "inline-block",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "160px",
                        }}
                      >
                        {el?.fileName}
                      </span>
                      <EditTwoTone
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEdit(el, 'fileName');
                        }}
                        style={{
                          color: "#178DFA",
                          fontSize: "14px",
                          marginLeft: "5px",
                          cursor: "pointer",
                          position: "absolute",
                          top: "50%",
                          right: "5%",
                          transform: "translate(20%, -50%)"
                        }}
                      />
                    </td>
                    <td style={{ position: "relative" }}>
                      <span
                        style={{
                          display: "inline-block",
                          overflow: "hidden",
                          maxWidth: "160px",
                        }}
                      >
                        {el?.address?.join(", ")}
                      </span>
                      <EditTwoTone
                        onClick={() => handleEdit(el, 'address')}
                        style={{
                          color: "#178DFA",
                          fontSize: "14px",
                          marginLeft: "5px",
                          cursor: "pointer",
                          position: "absolute",
                          top: "50%",
                          right: "5%",
                          transform: "translate(0%, -50%)"
                        }}
                      />
                    </td>

                    <td style={{ position: "relative" }}>
                      <span
                        style={{
                          display: "inline-block",
                          overflow: "hidden",
                          maxWidth: "160px",
                        }}
                      >
                        {el?.buyer?.map(person => person?.fullName).join(', ')}
                      </span>
                      <EditTwoTone
                        onClick={() => handleEdit(el, 'buyer')}
                        style={{
                          color: "#178DFA",
                          fontSize: "14px",
                          marginLeft: "5px",
                          cursor: "pointer",
                          position: "absolute",
                          top: "50%",
                          right: "5%",
                          transform: "translate(0%, -50%)"
                        }}
                      />
                    </td>
                    <td style={{ position: "relative" }}>
                      <span
                        style={{
                          display: "inline-block",
                          overflow: "hidden",
                          maxWidth: "160px",
                        }}
                      >
                        {el?.seller?.map(person => person?.fullName).join(', ')}
                      </span>
                      <EditTwoTone
                        onClick={() => handleEdit(el, 'seller')}
                        style={{
                          color: "#178DFA",
                          fontSize: "14px",
                          marginLeft: "5px",
                          cursor: "pointer",
                          position: "absolute",
                          top: "50%",
                          right: "5%",
                          transform: "translate(0%, -50%)"
                        }}
                      />
                    </td>
                    <td
                      style={{
                        color: "#77787b",
                        fontWeight: "bolder",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      <a href={el.fileLink} download> <ImFolderDownload style={{
                        opacity: 1
                      }} size={22}
                      /></a>
                    </td>
                    <td
                      style={{
                        color: "#77787b",
                        fontWeight: "bolder",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      <DeleteTwoTone onClick={() => handleDelete(el)} style={{ fontSize: "16px" }} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          !(loading || safeTDriveLoading) && <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80%",
              textAlign: "center",
            }}
          >
            <EmptyData />
          </div>
        )}
      </div>
      <div style={{
        width: "100%", display: 'flex',
        justifyContent: 'center',
        gap: '10px',
        alignItems: "center"
      }}>
        {safeTDriveTotalData && <button disabled={pageNumberInZip === 1}
          onClick={goToPreviousPage}
          style={{
            cursor: pageNumberInZip === 1 ? "auto" : "pointer",

            fontWeight: "bold", fontSize: "16px"
          }}
        >
          {'<'}
        </button>}
        {Array.from({ length: Math.ceil(safeTDriveTotalData / 25) }).map((_, index) => (
          <Button type='primary'
            disabled={pageNumberInZip == index + 1 ? true : false}
            key={index}
            onClick={() => handlePageChange(index + 1)}
            style={{
              borderRadius: "5px",
              backgroundColor: pageNumberInZip == index + 1 ? '#096dd9' : "white",
              color: pageNumberInZip == index + 1 ? 'white' : "black",
              fontWeight: "bold",
              cursor: 'pointer',
            }}
          >
            {index + 1}
          </Button>
        ))}
         {safeTDriveTotalData &&  <button
          disabled={pageNumberInZip === totalPages}
          onClick={goToNextPage}
          style={{
            cursor: pageNumberInZip === totalPages ? "auto" : "pointer",
            fontWeight: "bold", fontSize: "16px",

          }}

        >
          {'>'}
        </button> }
      </div>
    </div >
  );
}

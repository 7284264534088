import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import dotEnv from "dotenv";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { PropertyHandlers } from "../state/actions";
import styles from "../styles/Calendar.module.css";
import { Spin } from "antd";
dotEnv.config();
const adminURL = process.env.REACT_APP_ADMIN_BASE_URL;
const Calendar = ({ agentType, section, viewType, screen, offerId }) => {
  const delegate = (section === "DELEGATE");
  const location = useLocation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 480 });

  const { getIndividualTransactionData } = useSelector((state) => state.rtdTransactionLane, shallowEqual);
  const { urlShorthandData, urlShorthandLoading } = useSelector((state) => state.PropertyReducer);


  useEffect(() => {
    let selectedOfferId;
    let arr = (!["CLIENT_SCREEN", "ReadOnlyClientScreen"].includes(screen)) ? location.pathname.split("/") : [];
    let id = arr?.length && arr.pop();
    selectedOfferId = (["CLIENT_SCREEN", "ReadOnlyClientScreen"].includes(screen)) ? offerId : id.split("-").join("");
    if (agentType === "SELLER" ) {
      if (selectedOfferId) {
        let urlData = {
          urlData: {
            offerId: selectedOfferId,
            openFor:"SELLER_AGENT",
            key: localStorage.getItem("userAuth"),
            persistId: null,
            redirectTo: "webApp",
            agentType,
            ...(["CLIENT_SCREEN", "ReadOnlyClientScreen"].includes(screen) && {screen}),
          },
          ...(delegate ? {delegateOwnerId : getIndividualTransactionData?.currentAgentId?._id} : {})
        };
        dispatch(PropertyHandlers.urlShorthandApiCalling(urlData,"calendar"));
      }
    } else {
      if (agentType === "BUYER") {
        if (selectedOfferId) {
          let urlData = {
            urlData: {
              offerId: selectedOfferId,
              openFor:"BUYER_AGENT",
              key: localStorage.getItem("userAuth"),
              persistId: null,
              redirectTo: "webApp",
              agentType,
            ...(["CLIENT_SCREEN", "ReadOnlyClientScreen"].includes(screen) && {screen}),
            },
            ...(delegate ? {delegateOwnerId : getIndividualTransactionData?.buyerAgentId?._id} : {})
          };
          dispatch(PropertyHandlers.urlShorthandApiCalling(urlData,"calendar"));
        }
      }
    }
  }, []);


  return (
    <div
    className="mozScrollbar"
    style={{
      width: "100%",
      // border: "1px solid rgba(0,0,0,0.2)",
      // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
      borderRadius: "8px",
      padding: "0 1rem 1rem 1rem",
      marginLeft: "2px",
      marginTop:isMobile ? "8px" : "",
      height: (screen === 'ReadOnlyClientScreen') ? '75vh' :  isMobile ? "calc(100vh - 9.5rem)" : "calc(100vh - 125px)",
    //   position: "relative",
    //   minWidth:"700px",
      overflowX:"hidden",
    }}
  >
      {urlShorthandLoading ? <div style={{ width: '100%',height: "50vh", display: "flex", alignItems: "center", justifyContent: "center" }}><Spin size="large" /></div> :
        <iframe className={styles.taskAndCalendarIframe}
          style={{
            display: "grid",
            width: "100%",
            height: isMobile ? "calc(100vh - 270px)" : "calc(100vh - 200px)",
            overflowY: "hidden",
            border: "none"
          }}
          src={`${adminURL}/calendar/${urlShorthandData.urlShorthandData}?viewType=${viewType}&source=TRANSACTION_CALENDAR`}
          title="Calendar"
        />
      }
    </div>
  );
};

export { Calendar };
